.ad {
    position: relative;
    display: inline-block;
    padding-right: 55px;
    
   
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    padding-top: 5px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 5%;
    justify-content: flex-start;
    width: 90px;
    text-align: center;
  }
  
  .dropdown-content a {
    color: black;
   
    padding: 12px 16px;
    text-decoration: none;
    display: block;
   
   
  }
  
  .dropdown-content a:hover {background-color: #ddd;}
  
  .dropdown:hover .dropdown-content {display: block;}
  .username{
    padding-right: 20px;
  }