body {
  margin: auto;
  padding: auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #5a5a5a;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* GLOBAL STYLES
-------------------------------------------------- */
/* Padding below the footer and lighter body text */

body {
  padding-top: 3rem;
  padding-bottom: 3rem;
  color: #5a5a5a;
}
.dk{


}
.dkdn button{
margin: 3px;
width: 150;
height: 50px;
}
.nav-link{
  color: white;
 position: relative;
 bottom: 2px;
 

}
.btnAdd{
  color: white;
  height: 80px;
  width: 20px;
  text-decoration: none;

}
.show{
  display: block;
}
.hiden{
  display: none;
}
.sp{
  text-align: center;
 

}
Link span{
  text-decoration: none;
  font-size: medium;
}
.span{
  font-size: medium;
  font-weight: bold;
}
.page{
  display: flex;
  flex-wrap: wrap;
 
}
.pagi{
  text-align: center;
}

.side-bar{
  flex: 1;
  padding-right: 50px;
}
.item .sp{
text-align: center;
}
.Link{
  text-decoration: none;
  color: black;
}
.detail-image{
  padding-left:100px;
}
.login{
  width: 300px;
  
}
/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */

/* Carousel base class */
.carousel {
  margin-bottom: 4rem;
}
/* Since positioning the image, we need to help out the caption */
.carousel-caption {
  bottom: 3rem;
  z-index: 10;
}

/* Declare heights because of positioning of img element */
.carousel-item {
  height: 32rem;
  background-color: #777;
}
.carousel-item > img {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  height: 32rem;
}


/* MARKETING CONTENT
-------------------------------------------------- */

/* Center align the text within the three columns below the carousel */
.marketing .col-lg-4 {
  margin-bottom: 1.5rem;
  text-align: center;
}
.marketing h2 {
  font-weight: 400;
}
.marketing .col-lg-4 p {
  margin-right: .75rem;
  margin-left: .75rem;
}


/* Featurettes
------------------------- */

.featurette-divider {
  margin: 5rem 0; /* Space out the Bootstrap <hr> more */
}

/* Thin out the marketing headings */
.featurette-heading {
  font-weight: 300;
  line-height: 1;
  letter-spacing: -.05rem;
}
.btn-cart{
  
 border: none;
 width: 300px;
 height: 45px;
}
.price{
  font-size: 35px;
}
.username{
  color: white;
}
.btn-menu{

}
.spcate{
text-align: center;
}
.pro{
  text-align: center;
}
.dropbtn {
  background-color: #04AA6D;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
  padding-right: 25px;
  
 
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 5%;

  width: 100px;
  text-align: center;
 
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border-radius: 5%;
 
}

.dropdown-content a:hover {background-color: #ddd;}

.dropdown:hover .dropdown-content {
  display: block;
 

}
.username{
  padding-right: 20px;
}
.detail-image img:hover{
  transform: scale(1.1);
}
.cart{
  display: inline-block;
  position: relative;
}
.cart-note{
  position: absolute;
  padding: 1px 6px;
  line-height: 1.1rem;
right: -12px;
top: -6px;

  border-radius: 50%;
  color: white;
  background-color: red;
}

.danhmuc{
  background-color: white;
  text-align: center;
  height: auto;
  
  border-radius: 15px;
}
.itemlist{
  line-height: 50px;

}
.itemlist:hover{
  

}

/* RESPONSIVE CSS
-------------------------------------------------- */

@media (min-width: 40em) {
  /* Bump up size of carousel content */
  .carousel-caption p {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.4;
  }

  .featurette-heading {
    font-size: 50px;
  }
}

@media (min-width: 62em) {
  .featurette-heading {
    margin-top: 7rem;
  }
}
@media only screen and (min-width: 320px) and (max-width:812px) {
  .dk{
     padding-left: 100px;
     padding-right: 100px;
  }
}
/* Dành cho máy tính bảng chiều dọc */
/* @media only screen and (min-width: 768)and (min-width:1366) {
  .dk{
    padding-left: 100px;
    padding-right: 100px;
 }
} */

@media only screen and (min-width: 320px) and (max-width:768px) {
  .dk{
     padding-left: 100px;
     padding-right: 100px;
  }
  .pro img{
    width: 90%;
    height: 90%;
  }
}

@media only screen and (min-width: 768px) and (max-width:812px) {
  
  .pro img{
    width: 100%;
    height: 100%;
  }
 
}
@media only screen and (min-width: 812px) and (max-width:1366px) {
  .dk{
     padding-left: 200px;
     padding-right: 200px;
  }
  .pro img{
    width: 100%;
    height: 100%;
    margin: 0 10px;
  }
}
/* Dành cho máy tính bảng chiều ngang */
@media all and (min-width: 1367px) {
  .dk{
    padding-left: 500px;
padding-right: 500px;
  }
  .pro img{
    width: 100%;
   
  }
}


/* Dành cho màn hình desktop */

